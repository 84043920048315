<template>
  <div
    ref="loanAgreement"
    slot="content"
    class="loan-agreement-content"
    :style="`width: ${width}px; height:${height}px;`"
    v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.PRE_ORIGINATION_ENTER_LEAVE_SIGN_LOAN.value, path: $route.path}"
  >
    <iframe
      class="iframe"
      :src="loanAgreementUrl"></iframe>
    <v-overlay
      absolute
      :value="layouts.data.overlay"
    >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import { DataType, Sloth, Tool } from '@/assets/js/util'

export default {
  name: 'SignLoanAgreement',
  data: () => ({
    assist: {
      dataType: DataType,
      tool: new Tool()
    },
    height: '0',
    width: '',
    loanAgreementUrl: '',
    layouts: {
      data: {
        overlay: false
      }
    }
  }),
  mounted () {
    this.height = document.body.clientHeight - this.$refs.loanAgreement.offsetTop - 150

    const loanNo = this.$store.getters.getLoan.loanNo
    this.initWebSocket(loanNo)
    this.sendLoanAgreement(loanNo)
  },
  methods: {
    initWebSocket (loanNo) {
      const $this = this
      let data = {}
      this.judgeEnvironment()
      $this.sloth = new Sloth({
        url: $this.slothUrl,
        mode: 'ClientSide',
        wsid: loanNo
      })
      $this.sloth.init()
      $this.sloth.on('message', function (message) {
        switch (message.data.ticket) {
          case '2060': {
            data = message.data
            if (data.signatureStatus === DataType.SignStatusEnum.SIGNED.value) {
              $this.$router.push('/notification/102')
            }
            break
          }
          default:
            break
        }
      })
    },
    judgeEnvironment () {
      switch (process.env.VUE_APP_TITLE) {
        case 'develop':
          this.slothUrl = 'wsxch-dolphin.sztnb.com'
          break
        case 'production':
          this.slothUrl = 'wsxch-dolphin.sztus.com'
          break
        case 'verification':
          this.slothUrl = 'wsxch-dolphin.sztup.com'
          break
        default :
          break
      }
    },
    sendLoanAgreement (loanNo) {
      const input = {
        notificationType: 1,
        destination: loanNo
      }
      this.layouts.data.overlay = true
      OnlineApi.sendLoanAgreement(input, result => {
        if (this.assist.tool.isEmpty(result)) {
          this.$router.push('/notification/206')
          return
        }
        result += '?embed=1'
        this.loanAgreementUrl = result
        const loan = this.$store.getters.getLoan
        loan.loanAgreementUrl = result
        loan.loanAgreementUUid = loan.createUUid
        this.layouts.data.overlay = false
      }, () => {
        this.layouts.data.overlay = false
        this.$router.push('/notification/206')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.loan-agreement-content
  display: flex
  justify-content: center
.iframe
  width: 80%
  height: 95%
</style>
