<template>
  <div>
    <v-card flat class="review">
      <div class="review-title">Calculating your qualified amount.</div>
      <div class="review-title">It might take a while, please don’t refresh or leave the page!</div>
      <v-card-text class="review-content">
        <v-progress-circular
          :size="240"
          :width="12"
          color="secondary"
          indeterminate
        >
          <v-avatar color="transparent" size="200">
            <img v-if="layouts.data.portfolio.iconUrl" class="icon-url" :src="layouts.data.portfolio.iconUrl">
          </v-avatar>
        </v-progress-circular>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Tool } from '@/assets/js/util'
import { OnlineApi } from '@/api'

const tool = new Tool()
export default {
  name: 'WebGuidanceReview',
  components: { },
  props: {
    requestData: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      layouts: {
        data: {
          portfolio: {
            iconUrl: ''
          }
        }
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (tool.isNotEmpty(val)) {
          this.layouts.data.portfolio.iconUrl = val.iconUrl
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.getReport()
  },
  methods: {
    getReport () {
      // 在计算之前先查询该账户是否有风险
      OnlineApi.getReport({
        requestId: this.$store.getters.getRequestId
      }, () => {
        this.calculateLoanAmount()
      }, () => {
        this.$router.push('/notification/206')
      })
    },
    calculateLoanAmount () {
      setTimeout(() => {
        OnlineApi.calculateQualifiedAmount(result => {
          if (result.qualifiedPrincipal > 0) {
            this.$router.push('/disbursement/loan-application')
          } else {
            if (result?.isWithdrawn) {
              this.$router.push('/notification/301')
            } else if (result?.isNonVoiceAgent) {
              this.$router.push('/notification/202')
            } else {
              this.calculateFailure()
            }
          }
        }, (failure) => {
          this.calculateFailure()
        })
      }, 2000)
    },
    calculateFailure () {
      this.$router.push('/notification/203')
    }
  }
}
</script>

<style lang="sass" scoped>
.review
  height: 100%
  width: 600px
  margin-left: 50%
  -webkit-transform: translateX(-50%)
  display: flex
  flex-direction: column
  background-color: transparent

  &-title
    font-size: 18px
    font-weight: 700
    text-align: center
    padding: 12px

  &-content
    min-height: 500px
    display: flex
    align-items: center
    justify-content: center

  .icon-url
    width: 150px
    height: 150px
</style>
