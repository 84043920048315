<template>
  <div>
    <div v-if="layouts.processingDisplay">
      <div class="text-center f-s-20"> {{ ibvName }} is processing your request, please be patient and wait until it is
        done, thank you!
      </div>
      <div class="d-flex justify-center align-center height-500">
        <v-progress-circular
          :size="130"
          :width="15"
          color="var(--theme_secondary)"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>

    <div :style="settingStyle"
         v-else
         class="m-t-25 m-r-25">
      <div
        class="m-t-25"
        :style="assist.tool.isEmpty(height) ? 'height: calc(100vh - 25px - 25px - 76px)' : 'height:' + height">
        <iframe
          id="iFrameHolder"
          :src="widgetUrl" class="height-full width-full no-border"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import { DataType, Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'web-decision-logic',
  components: {},
  props: {
    requestData: {
      type: Object,
      default: () => {
      }
    },
    settingStyle: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    ibvProvider: {
      type: Number
    }
  },
  computed: {
    ibvName () {
      return tool.getEnumTextByValue(DataType.IbvProviderEnum, this.ibvProvider)
    }
  },
  data: () => ({
    ibvAuthorization: null,
    widgetUrl: null,
    requestCode: '',
    layouts: {
      processingDisplay: true,
      data: {
        bankData: {},
        errorMessage: ''
      }
    },
    assist: {
      tool: tool
    },
    timeIn: null
  }),
  mounted () {
    this.initIbv()
    // CommonRequest.addCustomerBehaviorRecords(
    //   this.$route.path,
    //   DataType.EventTypeEnum.ENTER_PAGE.value,
    //   DataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_DL_AUTHORIZATION_IFRAME.value
    // )
  },
  beforeDestroy () {
    clearInterval(this.timeIn)
  },
  methods: {
    // checkBlackList () {
    //   OnlineApi.checkBlackList((data) => {
    //     // res 为false，说明不在黑名单内
    //     if (data.result) {
    //       this.$router.push('/notification/200')
    //     } else {
    //       this.initIbv()
    //     }
    //   }, () => {
    //   })
    // },
    initIbv () {
      // 1、 Create Request Code
      // 2、 Update Ibv Data
      // 3、 Check Request Code Status
      const request = {
        // applicationId: this.$store.getters.getApplicationId,
        portfolioId: this.$store.getters.getPortfolioJson.portfolioId,
        providerEntity: this.ibvProvider,
        accountNumber: this.requestData.accountNo,
        routingNumber: this.requestData.routingNo,
        firstName: this.requestData.firstName,
        bankName: this.requestData.bankName,
        lastName: this.requestData.lastName,
        email: this.requestData.email,
        phone: this.requestData.cellPhone,
        accountType: Number(this.requestData.accountType)
      }
      OnlineApi.createRequestCode(request, result => {
        this.widgetUrl = result.widgetUrl
        this.requestCode = result.requestCode
        this.layouts.processingDisplay = false
        this.$store.commit('setRequestId', result.requestId) // 计算之前要用这个值
        this.createRequestCallBack(result)
      },
      () => {
        this.$router.push('/notification/206')
      })
    },
    createRequestCallBack (result) {
      this.$nextTick(() => {
        const iframe = document.getElementById('iFrameHolder')
        if (iframe !== null) {
          iframe.onload = () => {
            // CommonRequest.addCustomerBehaviorRecords(
            //   this.$route.path,
            //   DataType.EventTypeEnum.LEAVE_PAGE.value,
            //   DataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_DL_AUTHORIZATION_IFRAME.value
            // )
          }
        }
      })
      if (tool.isNotEmpty(result.requestCode) && result.requestCode.toString().length === 6) {
        this.checkRequestStatus(result)
      } else {
        this.$emit('backAction')
      }
    },
    checkRequestStatus (result) {
      const count = 0
      this.timeIn = setInterval(() => {
        OnlineApi.getRequestStatus({
          requestId: result.requestId
        }, result => {
          this.getStatusCallBack(count, result)
        }, () => {
          clearInterval(this.timeIn)
          this.$router.push('/notification/206')
        })
      }, 5000)
    },
    getStatusCallBack (count, result) {
      const status = Number.parseInt(result)
      if (DataType.IBVStatusEnum.LOGIN_VERIFIED.value === status || DataType.IBVStatusEnum.SUCCESSFUL.value === status) {
        this.$emit('backAction', this.requestData)
        clearInterval(this.timeIn)
      } else {
        count++
        // 10分钟
        if (count >= 120) {
          clearInterval(this.timeIn)
          this.$emit('backAction')
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.alert-title
  font-size: 20px
  font-weight: 700
  display: flex

.alert-text
  font-size: 16px

.confirm-btn
  background: var(--theme_primary) !important
  color: #F5F5F5
  width: 100%
  height: 50px !important
</style>
