import { render, staticRenderFns } from "./LoanAgreementSignature.vue?vue&type=template&id=6046f52c&scoped=true"
import script from "./LoanAgreementSignature.vue?vue&type=script&lang=js"
export * from "./LoanAgreementSignature.vue?vue&type=script&lang=js"
import style0 from "./LoanAgreementSignature.vue?vue&type=style&index=0&id=6046f52c&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6046f52c",
  null
  
)

export default component.exports